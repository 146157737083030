<template>
  <b-container class="greater-heights">
    <section class="relative desktop">
      <div class="reg-container">
        <div class="reg-text freight-big-pro-book text-brown">
          <p class="h5">
            <span v-scroll-to="{ el: '#register', offset: -100 }" role="button"
              >Register Now</span
            >
          </p>
        </div>
        <object
          data="/img/register_button.svg"
          type="image/svg+xml"
          class="regbutton zIndex-10"
        ></object>
      </div>
      <!-- images -->
      <b-row class="flex-md-row-reverse">
        <b-col
          md="6"
          class="d-md-flex justify-content-end align-items-end"
        >
          <div class="main-text">
            <div
              class="opacity-text overflow-hidden position-relative freight-big-pro-book medium-text"
              style="z-index: 10; opacity: 1;"
            >
              Taking
            </div>
            <div
              class="xm-text mb-2 text-uppercase freight-big-pro-book"
              style="z-index: 0"
            >
              YONGE & <br />
              ST. CLAIR
            </div>
            <div
              class="overflow-hidden position-relative freight-big-pro-book medium-text fade-in-up-element"
              style="z-index: 10"
            >
              To Greater Heights
            </div>
            <div
              class="sub-text mb-4 zIndex-10 lato-regular fade-in-up-element"
            >
              In an area of affluence and aspiration, opulence and opportunity,
              convenience, and centrality, The Hill invites you to experience a
              distinguished Toronto lifestyle. In the city's most connected and
              accessible neighbourhood, residents will find themselves
              surrounded by prestigious local landmarks and attractions, gourmet
              eateries and local favourties, designer brands, premium grocers,
              and of course, unrivaled access to public transit options. It's
              everything anyone could want. And more.
            </div>
          </div>
        </b-col>
        <b-col
          md="6"
          class="d-md-flex justify-content-center align-items-end flex-column"
        >
          <div
            class="img-container fade-in-up-element position-relative d-flex align-items-center"
          >
            <div class="overflow-hidden d-flex">
              <img
                src="/img/relax-1.png"
                alt="Relax Bar"
                class="w-100 d-block max-w-380"
              />
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          md="6"
          class="d-md-flex justify-content-center align-items-end flex-column"
        >
          <div
            class="img-container fade-in-up-element position-relative zIndex-10 d-flex align-items-center"
          >
            <div class="sorrel overflow-hidden d-flex">
              <img
                src="/img/sorrel.png"
                alt="Sorrel Restaurant"
                class="w-100 d-block max-w-380"
              />
            </div>
          </div>
        </b-col>
        <b-col md="6" class="">
          <div
            class="h-100 img-container fade-in-up-element overflow-hidden d-flex align-items-center"
          >
            <img
              src="/img/lcbo-5.png"
              alt="LCBO"
              class="w-100 d-block max-w-480"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          class="train-container d-md-flex flex-column align-items-end"
        >
          <div class="fade-in-up-element position-relative zIndex-10">
            <div class="train img-container overflow-hidden d-flex">
              <img
                src="/img/train-3.png"
                alt="TTC train pulling into the station"
                class="w-100 d-block max-w-300 h-130"
              />
            </div>
          </div>
          <div
            class="img-text position-relative zIndex-10 lato-regular sub-text max-w-420 d-flex flex-column w-100"
          >
            <div class="w-100">
              From the boutique shoppes along Yonge St. to the luxury brands of
              Yorkville, the Eaton Centre's array of world-class outlets and
              beyond, find home in the fashion capital of Toronto here at The
              Hill.
              <br />
              <br />
              Moreover, The Hill surrounds you with some of the most delectable
              dining options the city has to offer. Terroni, Scaramouche, Café
              Boloud, Morton's Steakhouse, and the local favourite Black Camel
              are but a few of Toronto's most-prized eateries. All waiting to be
              savoured just minutes from home.
            </div>
          </div>
          <div class="fade-in-up-element position-relative zIndex-10">
            <div class="img-container overflow-hidden d-flex">
              <img
                src="/img/market-4.png"
                alt="Summerhill Market"
                class="w-100 d-block max-w-380 h-130"
              />
            </div>
          </div>
        </b-col>
        <b-col md="6" class="d-md-flex flex-column">
          <div class="cafe-container">
            <!-- <object
              data="/img/animated_seal.svg"
              type="image/svg+xml"
              class="seal zIndex-10"
            ></object> -->
            <div
              class="img-container-right fade-in-up-element overflow-hidden d-flex"
            >
              <img
                src="/img/coffee-6.jpg"
                alt=""
                class="w-100 d-block max-w-320"
              />
            </div>
          </div>

          <div
            class="img-container-right fade-in-up-element overflow-hidden d-flex"
          >
            <img src="/img/city-7.jpg" alt="" class="w-100 d-block max-w-380" />
          </div>
        </b-col>
      </b-row>
      <b-row class="pb-4">
        <b-col md="4" class="d-md-flex flex-column align-items-end">
          <div
            class="fade-in-up-element overflow-hidden d-flex"
            style="padding-left:1rem"
          >
            <img src="/img/city-8.jpg" alt="" class="w-100 d-block max-w-235" />
          </div>
        </b-col>
        <b-col md="8">
          <div
            class="fade-in-up-element overflow-hidden d-flex padding-bottom-img sm-screen-position"
          >
            <img src="/img/city-9.jpg" alt="" class="w-100 d-block max-w-570" />
          </div>
          <!-- <div
            class="fade-in-up-element overflow-hidden d-flex sm-screen-position"
          >
            <img src="/img/park2.jpg" alt="" class="w-100 d-block max-w-300" />
          </div> -->
        </b-col>
      </b-row>
    </section>
    <section class="pb-4 mobile">
      <b-row>
        <b-col
        >
          <div class="main-text">
            <div
              class="opacity-text overflow-hidden position-relative freight-big-pro-book medium-text"
              style="z-index: 10; opacity: 1;"
            >
              Taking
            </div>
            <div
              class="xm-text mb-2 text-uppercase freight-big-pro-book"
              style="z-index: 0"
            >
              YONGE & <br />
              ST. CLAIR
            </div>
            <div
              class="overflow-hidden position-relative freight-big-pro-book medium-text fade-in-up-element"
              style="z-index: 10"
            >
              To Greater Heights
            </div>
            <div
              class="sub-text mb-4 zIndex-10 lato-regular fade-in-up-element"
            >
              In an area of affluence and aspiration, opulence and opportunity,
              convenience, and centrality, The Hill invites you to experience a
              distinguished Toronto lifestyle. In the city's most connected and
              accessible neighbourhood, residents will find themselves
              surrounded by prestigious local landmarks and attractions, gourmet
              eateries and local favourties, designer brands, premium grocers,
              and of course, unrivaled access to public transit options. It's
              everything anyone could want. And more.
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" class="pt-5 pl-0 pr-2">
          <div
            class="img-container fade-in-up-element d-flex align-items-center"
          >
            <div class="sorrel overflow-hidden d-flex">
              <img
                src="/img/sorrel.png"
                alt="Sorrel Restaurant"
                class="w-100 d-block max-w-380"
              />
            </div>
          </div>
        </b-col>
        <b-col cols="6" class="pr-0 pl-2">
          <div
            class="img-container fade-in-up-element d-flex align-items-center"
          >
            <div class="overflow-hidden d-flex">
              <img
                src="/img/relax-1.png"
                alt="Relax Bar"
                class="w-100 d-block max-w-380"
              />
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="px-0 w-100">
          <div
            class="h-100 w-100 img-container fade-in-up-element overflow-hidden"
          >
            <img
              src="/img/lcbo-5.png"
              alt="LCBO"
              class="w-100 d-block"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" class="pt-5 pl-0 pr-2">
          <div class="fade-in-up-element zIndex-10">
            <div class="train img-container overflow-hidden d-flex">
              <img
                src="/img/train-3.png"
                alt="TTC train pulling into the station"
                class="w-100 d-block max-w-300 h-130"
              />
            </div>
          </div>
        </b-col>
        <b-col cols="6" class="pr-0 pl-2">
          <div class="fade-in-up-element zIndex-10">
            <div
              class="img-container fade-in-up-element overflow-hidden d-flex"
            >
              <img
                src="/img/coffee-6.jpg"
                alt=""
                class="w-100 d-block max-w-320"
              />
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div
            class="img-text lato-regular sub-text px-2"
          >
            <div class="w-100">
              From the boutique shoppes along Yonge St. to the luxury brands of
              Yorkville, the Eaton Centre's array of world-class outlets and
              beyond, find home in the fashion capital of Toronto here at The
              Hill.
              <br />
              <br />
              Moreover, The Hill surrounds you with some of the most delectable
              dining options the city has to offer. Terroni, Scaramouche, Café
              Boloud, Morton's Steakhouse, and the local favourite Black Camel
              are but a few of Toronto's most-prized eateries. All waiting to be
              savoured just minutes from home.
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="px-0">
          <div
            class="h-100 img-container fade-in-up-element overflow-hidden"
          >
            <img
              src="/img/market-4.png"
              alt="Summerhill Market"
              class="w-100 d-block"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <!-- <b-col cols="6" class="pt-5 pl-0 pr-2">
          <div
            class="img-container fade-in-up-element zIndex-10 d-flex align-items-center"
          >
            <div class="sorrel overflow-hidden d-flex">
              <img src="/img/park.jpg" alt="" class="w-100 d-block max-w-380" />
            </div>
          </div>
          <div
            class="img-container fade-in-up-element position-relative zIndex-10 d-flex align-items-center"
          >
            <div class="sorrel overflow-hidden d-flex">
              <img src="/img/park2.jpg" alt="" class="w-100 d-block max-w-300" />

            </div>
          </div>
        </b-col> -->
        <b-col cols="12" class="pr-0 pl-0">
          <div
            class="img-container fade-in-up-element position-relative d-flex align-items-center"
          >
            <div class="overflow-hidden d-flex">
              <img src="/img/city-7.jpg" alt="" class="w-100 d-block max-w-380" />
            </div>
          </div>
          <!-- <div
            class="img-container fade-in-up-element position-relative d-flex align-items-center"
          >
            <div class="overflow-hidden d-flex">
              <img src="/img/trail.jpg" alt="" class="w-100 d-block max-w-450" />
            </div>
          </div> -->
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh();
    setTimeout(() => {
      this.textReveal();
    }, 500);
  },
  methods: {
    textReveal() {
      this.showText = gsap;

      var scrollTimeline = this.showText
        .timeline({ paused: true })
        .from(".opacity-text", { opacity: 0, duration: 1 });

      this.showTextTrigger = ScrollTrigger.create({
        trigger: ".b-container",
        start: "top top",
        stagger: 0.2,
        animation: scrollTimeline,
        markers: false
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.greater-heights {
  padding-bottom: 100px;
  .mobile {
    display: none;
    @media screen and (max-width: 500px){
      display: block;
    }
  }
  .desktop {
    display: block;
    @media screen and (max-width: 500px){
      display: none;
    }
  }
  .img-container, .img-text {
    padding: 50px 40px 0px 0px;
  }
  .img-container-right {
    padding: 0px 0px 68px 0px;
  }
  .padding-bottom-img {
    padding-bottom: 68px;
  }
  .padding-right {
    padding-right: 40px;
  }
  .reg-container {
    position: relative;
    .regbutton {
      position: absolute;
      top: 1.5rem;
      left: 30.5rem;
      max-width: 450px;
      z-index: 9999;
    }
    .reg-text {
      position: absolute;
      top: 4rem;
      left: 33.1rem;
      z-index: 99999;
    }
  }
  .opacity-text {
    opacity: 1;
  }
  .sub-text {
    font-size: 12px;
  }
  .seal {
    z-index: 1000;
    max-width: 150px;
    position: absolute;
    left: 16.5rem;
    top: 8.5rem;
  }
  @media screen and (max-width: 1199px) {
  .reg-container {
    position: relative;
    .regbutton {
      position: absolute;
      top: 1.5rem;
      left: 25.5rem;
      max-width: 450px;
      z-index: 1000;
    }
    .reg-text {
      position: absolute;
      top: 4rem;
      left: 28.1rem;
      z-index: 99999;
    }
  }
}

  @media screen and (max-width: 991px) {
    .train-container {
      padding-top: 0px;
    }
    .reg-container {
    position: relative;
    .regbutton {
      position: absolute;
      top: -4rem;
      left: 20rem;
      max-width: 350px;
      z-index: 1000;
    }
    .reg-text {
      position: absolute;
      top: -1.8rem;
      left: 22rem;
      z-index: 99999;
      p {
        font-size: 16px;
      }
    }
  }
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 50px;
    .padding-bottom-img {
    padding-bottom: 0px;
  }
    .reg-container {
    position: relative;
    .regbutton {
      position: absolute;
      top: -5rem;
      left: 0rem;
      max-width: 350px;
      z-index: 1000;
    }
    .reg-text {
      position: absolute;
      top: -3rem;
      left: 1.9rem;
      z-index: 99999;
    }
  }
    .sub-text {
      max-width: unset;
    }
    .main-text {
      padding: 0px 40px 0px 40px;
    }
    img {
      // max-width: 350px;
      padding-bottom: 20px;
    }
    .img-container, .img-container-right {
      justify-content: center;
      padding: 0;
    }
    .padding-right, .sm-screen-position {
      justify-content: center;
      padding: 0;
    }
    .img-text {
      padding: 0px 45px 20px 45px;
    }
  }
  @media screen and (max-width: 500px) {
    .img-text {
      padding: 0px 0px 20px 0px;
    }
    .main-text {
      padding: 0px 10px 0px 10px;
    }
    .seal {
    max-width: 180px;
    left: 16.5rem;
    top: 10rem;
  }
  .reg-container {
    position: relative;
    .regbutton {
      position: absolute;
      top: -5rem;
      left: -0.5rem;
      max-width: 300px;
      z-index: 1000;
    }
    .reg-text {
      position: absolute;
      top: -3rem;
      left: 1rem;
      z-index: 99999;
      p {
        font-size: 14px;
      }
    }
  }
  }
}
</style>
